label {
    color: white;
    margin-bottom: 0.5rem;
}

form {
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
    
}

from input, form textarea, form input {
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
background-color: rgba(225, 225, 225, 0.1);
border-color: rgba(225, 225, 225, 0.1);
color: #f4f4f4;
}


