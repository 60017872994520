.mask {
    width: 100%;
    height: 100vh;
    position: relative;
   
}

.intro-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }

.mask::after {
content: "";
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
background-color: black;
opacity: 0.8;
}

.hero {
    height: 100%;
    width: 100%;
    
}

.hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.hero .content h1 {
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
    color: orange;
}

.hero .content p {
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
}

.content .btn {
    margin: 1rem 0.5rem;
    width: 100%; 
}


@media screen and (max-width: 768px) {
    .hero .content h1 {
        font-size: 3rem; /* Adjust font size for smaller screens */
    }

    .hero .content p {
        font-size: 1.2rem; /* Adjust font size for smaller screens */
    }

    .btn {
        display: block;
        width: 100%;
        margin-bottom: 10px; /* Add some space between the buttons */
      }
}


@media screen and (max-width: 515px) {
    .hero .content h1 {
        font-size: 2rem; /* Adjust font size for even smaller screens */
        padding: 0.3rem 0 1rem; /* Adjust padding for smaller screens */
    }

    .hero .content p {
        font-size: 1rem; /* Adjust font size for even smaller screens */
    }

    .content .btn {
        margin: 0.3rem 0.2rem; /* Adjust button margins for even smaller screens */
    }
}

@media screen and (max-width: 430px) {
    .hero .content h1 {
        font-size: 1.2rem; /* Adjust font size for screens with a maximum width of 320px */
        padding: 0.2rem 0 0.6rem; /* Adjust padding for screens with a maximum width of 320px */
    }

    .hero .content p {
        font-size: 0.9rem; /* Adjust font size for screens with a maximum width of 320px */
    }

    .content .btn {
        margin: 0.1rem 0.2rem; /* Adjust button margins for screens with a maximum width of 320px */
       margin-top: 0.5rem;
    }
}




