
.skills-section {
    background-color: rgba(19, 19, 19, 0.8);
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
    margin-top: 100px;
  }
  
  .skills-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .skill-item {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background-color: #fca311; 
    color: black;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase; 
    letter-spacing: 1px; 
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .skill-item:hover {
  
    transform: scale(1.05); 
    
  }
  

  @media screen and (max-width: 768px) {
    .skills-section {
        padding: 20px; /* Reduce padding for smaller screens */
        margin-top: 50px; /* Reduce margin-top for smaller screens */
    }

    .skill-item {
        padding: 8px 16px; /* Adjust padding for smaller screens */
        margin: 8px; /* Adjust margin for smaller screens */
        font-size: 14px; /* Adjust font size for smaller screens */
    }
}


@media screen and (max-width: 515px) {
  .skills-section {
      padding: 15px; /* Further reduce padding for even smaller screens */
      margin-top: 30px; /* Further reduce margin-top for even smaller screens */
  }

  .skill-item {
      padding: 6px 12px; /* Further adjust padding for even smaller screens */
      margin: 6px; /* Further adjust margin for even smaller screens */
      font-size: 12px; /* Further adjust font size for even smaller screens */
  }
}

@media screen and (max-width: 430px) {
  .skills-section {
      padding: 5px; /* Further reduce padding for screens with a maximum width of 320px */
      margin-top: 10px; /* Further reduce margin-top for screens with a maximum width of 320px */
  }

  .skill-item {
      padding: 4px 8px; /* Further adjust padding for screens with a maximum width of 320px */
      margin: 4px; /* Further adjust margin for screens with a maximum width of 320px */
      font-size: 8px; /* Further adjust font size for screens with a maximum width of 320px */
  }
}