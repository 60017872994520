.project-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
}

.project-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}

.project-card {
    background: #1a1919;
    padding: 1.2rem 1rem;
}

.project-card img {
    width: 100%;
}

.project-title {
    color: #fff;
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pro-details p {
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}

.pro-btns {
    display: flex;
    padding: 0.5rem 0;
    justify-content: center;
}

.pro-btns .btn {
padding: 0.5rem 1rem;
text-align: center;
}

@media screen and (max-width: 768px) {
    .project-container {
        grid-template-columns: repeat(1, 1fr); /* Change to single-column layout for smaller screens */
        grid-gap: 20px; /* Reduce the gap between project cards */
    }

    .project-card {
        padding: 1rem; /* Adjust padding for project cards */
    }
}

@media screen and (max-width: 515px) {
    .project-container {
        padding-bottom: 3rem; /* Further reduce padding at the bottom for smaller screens */
    }

    .project-card {
        padding: 0.8rem; /* Further adjust padding for project cards */
    }

    .project-title {
        font-size: 1.2rem; /* Adjust font size for smaller screens */
    }

    .pro-details p {
        font-size: 1rem; /* Adjust font size for even smaller screens */
    }
}

@media screen and (max-width: 430px) {
    .project-container {
        grid-gap: 5px; /* Further reduce the gap between project cards for screens with a maximum width of 320px */
    }

    .project-card {
        padding: 0.4rem; /* Further adjust padding for project cards on smaller screens */
    }

    .project-title {
        font-size: 0.9rem; /* Further reduce font size for screens with a maximum width of 320px */
    }

    .pro-details p {
        font-size: 0.8rem; /* Further reduce font size for screens with a maximum width of 320px */
    }
}