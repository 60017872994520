.about {
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;

}

.about .left {
    text-align: left;
    margin: auto;
    padding: 1rem;
    max-width: 413px;
}

.about .left p {
    margin: 1.2rem 0;
    text-align: left;
    font-size: 18px;
}

.about .right {
    max-width: 550px;
}

.right .image-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .image-container img {
    max-width: 350px; /* Make the image responsive */
    height: auto; /* Automatically adjust the height */
}

@media screen and (max-width: 768px) {
    .about {
        flex-direction: column; /* Stack content vertically */
        align-items: center; /* Center content vertically */
        text-align: center; /* Center text */
        margin: 2rem 0;
    }

    .about .left {
        max-width: 100%; /* Full width for text */
        text-align: center;
        padding: 0.5rem;
    }

    .about .left p {
        text-align: center;
        font-size: 16px; /* Adjust font size for smaller screens */
    }

    .about .right {
        max-width: 50%; /* Full width for the image */
        margin-top: 2rem; /* Add some space between the text and image */
    }

    .right .image-container {
        grid-template-columns: repeat(1, 1fr); /* Single column layout */
    }
}

@media screen and (max-width: 515px) {
    .about .left p {
        font-size: 14px; /* Adjust font size for even smaller screens */
    }

    .about .right {
        max-width: 70%; /* Adjust width for the image */
        margin-top: 1rem; /* Add some space between the text and image */
    }
    
    /* Additional media query for screens with a maximum width of 515px */
    @media screen and (max-width: 515px) {
        .about .left p {
            font-size: 12px; /* Further reduce font size for very small screens */
        }
    }
}


@media screen and (max-width: 430px) {
    .about .left p {
        font-size: 17px; /* Further reduce font size for screens with a maximum width of 320px */
    }

    .about .right {
        max-width: 90%; /* Adjust width for the image on screens with a maximum width of 320px */
    }
}