@import url('https://fonts.googleapis.com/css2?family=Inclusive+Sans:ital@0;1&family=Merriweather&family=Montserrat:wght@100;400;900&family=Open+Sans:wght@300&family=Poppins&family=Sacramento&family=Ubuntu&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inclusive Sans', sans-serif;
font-family: 'Merriweather', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Sacramento', cursive;
font-family: 'Ubuntu', sans-serif;
}

body {
  background: #000;
}

h1,
h4,
p,
a {
  color: white;
  text-decoration: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #fca311;
  color: black;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  /* background: rgba(255, 255, 255, 0.2): */
  background: transparent;
  color: white;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  transition: 0.3s;
}