.footer {
    width: 100%;
    padding: 6rem 0;
    background-color: rgba(19, 19, 19, 0.8);
}

.footer-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
grid-gap: 40px;
}

.left {
height: 100%;
max-width: 100%;
display: flex;
flex-direction: column;
padding: 1rem;
}

.location {
    display: flex;
    margin-bottom: 0.8rem;
}

.location p {
    line-height: 30px;
}

.right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.social {
    margin-top: 1rem;
}

h4 {
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}

p {
    font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
    .footer-container {
        grid-template-columns: repeat(1, 1fr); /* Change to single-column layout for smaller screens */
        grid-gap: 20px; /* Reduce the gap between columns */
    }

    .left, .right {
        max-width: 100%;
    }
}

@media screen and (max-width: 515px) {
    .footer {
        padding: 3rem 0; /* Further reduce padding for smaller screens */
    }

    .footer-container {
        grid-gap: 15px; /* Further reduce the gap between columns for smaller screens */
    }

    h4 {
        font-size: 1rem; /* Adjust font size for even smaller screens */
    }

    p {
        font-size: 1rem; /* Adjust font size for even smaller screens */
    }
}


@media screen and (max-width: 430px) {
    .footer {
        padding: 1rem 0; /* Further reduce padding for screens with a maximum width of 320px */
    }

    h4 {
        font-size: 0.8rem; /* Further adjust font size for screens with a maximum width of 320px */
    }

    p {
        font-size: 0.8rem; /* Further adjust font size for screens with a maximum width of 320px */
    }
}